
import { Options, Vue } from 'vue-class-component';
import OperationResult from '../layout/OperationResult.vue';

@Options({
    components: {
        OperationResult
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'permission'
        }
    },
    data(){
        return {
            formData: {
                name: '',
                display: '',
                guard_name: ''
            },
            guards: [],
            permissionsOption: [],
            btnLoading: false,
            notification: {
                show: false,
                title: '',
                content: '',
                type: 'error'
            },
            result: {
                show: false,
                title: '',
                toTitle: '权限列表页',
                nextText: '',
                duration: 5
            }
        }
    },
    watch: {
        data(){
            if(this.data) this.formData = this.data;
            if(this.type === 'role') this.getPermissionsGuard(this.formData.guard_name);
        },
        'permissionsOption' :{
            handler(){
                this.formatPermissions();
            },
            deep: true
        }
    },
    mounted(){
        if(this.data) this.formData = this.data;
        else this.result.nextText = '继续创建';
        this.getAllGuards();
    },
    methods: {
        getAllGuards(){
            this.$api.getGuards().then((res: any) => {
                this.guards = res.data;
            })
        },
        chooseGuard(index: number){
            this.formData.guard_name = this.guards[index];
            if(this.type === 'role') this.getPermissionsGuard(this.guards[index]);
        },
        getPermissionsGuard(guard: string){
            this.$api.getPermissionsByGuard(guard).then((res: any) => {
                this.permissionsOption = res.data;
                const permissions = this.permissionsOption,
                    checkedArr = this.formData.permissions;
                if(this.formData.permissions){
                    permissions.forEach((item: any, index: number) => {
                        checkedArr.forEach((child: any) => {
                            if(child.name === item.name) item.checked = true;
                        })
                    })
                }
            })
        },
        formatPermissions(){
            const checkArr: any = [];
            this.permissionsOption.forEach((item: any) => {
                if(item.checked) checkArr.push(item);
            })
            this.formData.permissions = checkArr;
        },
        resetData(){
            this.formData = this.data
        },
        resultNext(){
            this.result.show = false;
            this.result.duration = 5;
            this.formData = {
                name: '',
                display: '',
                guard_name: ''
            };
            this.permissionsOption = [];
        },
        submit(){
            this.btnLoading = true;
            if(this.type === 'permission'){
                if(this.data){
                    console.log('updatePermissionById')
                    this.$api.updatePermissionById(Number(this.data.id), this.formData).then((res: any) => {
                        this.btnLoading = false;
                        this.result.show = true;
                        if(res.msg === 'success'){
                            this.result.status = 'SUCCESS';
                            this.result.title = '权限信息更新成功';
                        } else {
                            this.result.status = 'ERROR';
                            this.result.title = '接口报错，请联系技术人员检查';
                        }
                    })
                } else {
                    console.log('createNewPermission')
                    this.$api.createNewPermission(this.formData).then((res: any) => {
                        this.btnLoading = false;
                        this.result.show = true;
                        if(res.msg === 'success'){
                            this.result.status = 'SUCCESS';
                            this.result.title = '权限创建成功';
                        } else {
                            this.result.status = 'ERROR';
                            this.result.title = '接口报错，请联系技术人员检查';
                        }
                    })
                }
            } else {
                if(this.data){
                    console.log('updateRoleById')
                    this.$api.updateRoleById(Number(this.data.id), this.formData).then((res: any) => {
                        this.btnLoading = false;
                        this.result.show = true;
                        if(res.msg === 'success'){
                            this.result.status = 'SUCCESS';
                            this.result.title = '角色信息更新成功';
                        } else {
                            this.result.status = 'ERROR';
                            this.result.title = '接口报错，请联系技术人员检查';
                        }
                    })
                } else {
                    console.log('createNewRole')
                    this.$api.createNewRole(this.formData).then((res: any) => {
                        this.btnLoading = false;
                        this.result.show = true;
                        if(res.msg === 'success'){
                            this.result.status = 'SUCCESS';
                            this.result.title = '权限创建成功';
                        } else {
                            this.result.status = 'ERROR';
                            this.result.title = '接口报错，请联系技术人员检查';
                        }
                    })
                }
            }
        },
    }
})

export default class PermissionForm extends Vue {}
