<template>
    <div class="form-container" v-if="!result.show">
        <div class="form-head">
            <h4>{{ title }}</h4>
            <p>此功能用于后端接口开发，请谨慎操作！</p>
        </div>
        <div class="form-body">
            <div class="form-item">
                <div class="label">
                    <div class="label-container">
                        <span>name</span>
                        <em>*</em>
                    </div>
                </div>
                <div class="item">
                    <n-input
                        v-model:value="formData.name"
                        placeholder="请输入name"
                    />
                </div>
            </div>
            <div class="form-item">
                <div class="label">
                    <div class="label-container">
                        <span>显示名</span>
                        <em>*</em>
                    </div>
                </div>
                <div class="item">
                    <n-input
                        v-model:value="formData.display"
                        placeholder="请输入显示名"
                    />
                </div>
            </div>
            <div class="form-item">
                <div class="label">
                    <div class="label-container">
                        <span>守卫</span>
                        <em>*</em>
                    </div>
                </div>
                <div class="item">
                    <n-select
                        v-model:value="formData.guard_name"
                        placeholder="请选择守卫"
                    >
                        <template v-for="(item, index) in guards" :key="index">
                            <n-select-option
                                :label="item"
                                :index="index"
                                :checked="this.formData.guard_name === item"
                                @choose="chooseGuard"
                            ></n-select-option>
                        </template>
                    </n-select>
                </div>
            </div>

            <div class="form-item" v-if="type === 'role'">
                <div class="label">
                    <div class="lable-container">
                        <span>权限</span>
                        <em>*</em>
                    </div>
                </div>
                <div class="item checkbox-group">
                    <template v-if="permissionsOption.length > 0">
                        <n-checkbox class="checkbox-item"
                            v-for="(item, index) in permissionsOption" :key="index"
                            v-model:checked="item.checked"
                        >{{ item.display }}</n-checkbox>
                    </template>
                    <span v-else class="none">该guard下暂无权限</span>
                </div>
            </div>

        </div>
        <div class="form-bottom">
            <n-button class="btn" size="large" @click="resetData" round>重 置</n-button>
            <n-button class="btn"
                size="large" type="primary"
                :loading="btnLoading"
                @click="submit" round
            >确 定</n-button>
        </div>
    </div>
    <operation-result
        v-model:show="result.show"
        :title="result.title"
        :toTitle="result.toTitle"
        :status="result.status"
        :duration="result.duration"
        :nextText="result.nextText"
        @go-back="$router.go(-1)"
        @go-next="resultNext"
    />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import OperationResult from '../layout/OperationResult.vue';

@Options({
    components: {
        OperationResult
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'permission'
        }
    },
    data(){
        return {
            formData: {
                name: '',
                display: '',
                guard_name: ''
            },
            guards: [],
            permissionsOption: [],
            btnLoading: false,
            notification: {
                show: false,
                title: '',
                content: '',
                type: 'error'
            },
            result: {
                show: false,
                title: '',
                toTitle: '权限列表页',
                nextText: '',
                duration: 5
            }
        }
    },
    watch: {
        data(){
            if(this.data) this.formData = this.data;
            if(this.type === 'role') this.getPermissionsGuard(this.formData.guard_name);
        },
        'permissionsOption' :{
            handler(){
                this.formatPermissions();
            },
            deep: true
        }
    },
    mounted(){
        if(this.data) this.formData = this.data;
        else this.result.nextText = '继续创建';
        this.getAllGuards();
    },
    methods: {
        getAllGuards(){
            this.$api.getGuards().then((res: any) => {
                this.guards = res.data;
            })
        },
        chooseGuard(index: number){
            this.formData.guard_name = this.guards[index];
            if(this.type === 'role') this.getPermissionsGuard(this.guards[index]);
        },
        getPermissionsGuard(guard: string){
            this.$api.getPermissionsByGuard(guard).then((res: any) => {
                this.permissionsOption = res.data;
                const permissions = this.permissionsOption,
                    checkedArr = this.formData.permissions;
                if(this.formData.permissions){
                    permissions.forEach((item: any, index: number) => {
                        checkedArr.forEach((child: any) => {
                            if(child.name === item.name) item.checked = true;
                        })
                    })
                }
            })
        },
        formatPermissions(){
            const checkArr: any = [];
            this.permissionsOption.forEach((item: any) => {
                if(item.checked) checkArr.push(item);
            })
            this.formData.permissions = checkArr;
        },
        resetData(){
            this.formData = this.data
        },
        resultNext(){
            this.result.show = false;
            this.result.duration = 5;
            this.formData = {
                name: '',
                display: '',
                guard_name: ''
            };
            this.permissionsOption = [];
        },
        submit(){
            this.btnLoading = true;
            if(this.type === 'permission'){
                if(this.data){
                    console.log('updatePermissionById')
                    this.$api.updatePermissionById(Number(this.data.id), this.formData).then((res: any) => {
                        this.btnLoading = false;
                        this.result.show = true;
                        if(res.msg === 'success'){
                            this.result.status = 'SUCCESS';
                            this.result.title = '权限信息更新成功';
                        } else {
                            this.result.status = 'ERROR';
                            this.result.title = '接口报错，请联系技术人员检查';
                        }
                    })
                } else {
                    console.log('createNewPermission')
                    this.$api.createNewPermission(this.formData).then((res: any) => {
                        this.btnLoading = false;
                        this.result.show = true;
                        if(res.msg === 'success'){
                            this.result.status = 'SUCCESS';
                            this.result.title = '权限创建成功';
                        } else {
                            this.result.status = 'ERROR';
                            this.result.title = '接口报错，请联系技术人员检查';
                        }
                    })
                }
            } else {
                if(this.data){
                    console.log('updateRoleById')
                    this.$api.updateRoleById(Number(this.data.id), this.formData).then((res: any) => {
                        this.btnLoading = false;
                        this.result.show = true;
                        if(res.msg === 'success'){
                            this.result.status = 'SUCCESS';
                            this.result.title = '角色信息更新成功';
                        } else {
                            this.result.status = 'ERROR';
                            this.result.title = '接口报错，请联系技术人员检查';
                        }
                    })
                } else {
                    console.log('createNewRole')
                    this.$api.createNewRole(this.formData).then((res: any) => {
                        this.btnLoading = false;
                        this.result.show = true;
                        if(res.msg === 'success'){
                            this.result.status = 'SUCCESS';
                            this.result.title = '权限创建成功';
                        } else {
                            this.result.status = 'ERROR';
                            this.result.title = '接口报错，请联系技术人员检查';
                        }
                    })
                }
            }
        },
    }
})

export default class PermissionForm extends Vue {}
</script>

<style lang="scss" scoped>
@import 'form-style';
</style>
